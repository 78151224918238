import React, { useEffect, useReducer, createContext } from "react";

import introducerReducer from "../reducers/introducer-reducer";
import { setIntroducer } from "../actions";

export const IntroducerContext = createContext();
const IntroducerProvider = IntroducerContext.Provider;

export function IntroducerWrapper({ children }) {
  const defaultIntroducer = { introducedByCode: null };

  const storedIntroducer =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("introducer"))
      : null;
  const [introducer, dispatchIntroducer] = useReducer(
    introducerReducer,
    storedIntroducer || defaultIntroducer
  );

  // updates introducer on page refresh
  useEffect(() => {
    if (storedIntroducer) {
      // DISPATCH INTRODUCER
      //console.log("dispatching introducer...", storedIntroducer);
      dispatchIntroducer(setIntroducer(storedIntroducer));
    } else {
      //console.log("dispatching default introducer...", defaultIntroducer);
      dispatchIntroducer(setIntroducer(defaultIntroducer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntroducerProvider
      value={{ introducer, dispatchIntroducer, defaultIntroducer }}
    >
      {children}
    </IntroducerProvider>
  );
}
